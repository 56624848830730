import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b8f9e8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 2
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.parentId'),
                      prop: "parentId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[0] || (_cache[0] = e => _ctx.form.parentId = _ctx.valid(e)),
                          modelValue: _ctx.form.parentId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.parentId) = $event)),
                          placeholder: _ctx.$t('menu.form.parentId_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.index'),
                      prop: "index"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: _ctx.form.index,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.index) = $event)),
                          min: 0,
                          max: 1000,
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.caption'),
                      prop: "caption"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[3] || (_cache[3] = e => _ctx.form.caption = _ctx.valid(e)),
                          modelValue: _ctx.form.caption,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.caption) = $event)),
                          placeholder: _ctx.$t('menu.form.caption_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.path'),
                      prop: "path"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[5] || (_cache[5] = e => _ctx.form.path = _ctx.valid(e)),
                          modelValue: _ctx.form.path,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.path) = $event)),
                          placeholder: _ctx.$t('menu.form.path_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.page'),
                      prop: "page"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[7] || (_cache[7] = e => _ctx.form.page = _ctx.valid(e)),
                          modelValue: _ctx.form.page,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.page) = $event)),
                          placeholder: _ctx.$t('menu.form.page_placeholder'),
                          maxlength: "250",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.icon'),
                      prop: "icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[9] || (_cache[9] = e => _ctx.form.icon = _ctx.valid(e)),
                          modelValue: _ctx.form.icon,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.icon) = $event)),
                          placeholder: _ctx.$t('menu.form.icon_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.pic'),
                      prop: "pic"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[11] || (_cache[11] = e => _ctx.form.pic = _ctx.valid(e)),
                          modelValue: _ctx.form.pic,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.pic) = $event)),
                          placeholder: _ctx.$t('menu.form.pic_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.permission'),
                      prop: "permission"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[13] || (_cache[13] = e => _ctx.form.permission = _ctx.valid(e)),
                          modelValue: _ctx.form.permission,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.permission) = $event)),
                          placeholder: _ctx.$t('menu.form.permission_placeholder'),
                          maxlength: "50",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.show'),
                      prop: "show"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('show',el),
                          modelValue: _ctx.form.show,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.show) = $event)),
                          clearable: "",
                          placeholder: _ctx.$t('menu.form.show_placeholder'),
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[16] || (_cache[16] = (val)=>{_ctx.selectOnChange(val,'show')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'show'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('menu.form.prop'),
                      prop: "prop"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('prop',el),
                          modelValue: _ctx.form.show,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.show) = $event)),
                          clearable: "",
                          placeholder: _ctx.$t('menu.form.prop_placeholder'),
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[18] || (_cache[18] = (val)=>{_ctx.selectOnChange(val,'prop')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'prop'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}